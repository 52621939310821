import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate verify`}</strong>{` -- verify a certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate verify <crt-file> [--host=<host>]
[--roots=<root-bundle>] [--servername=<servername>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate verify`}</strong>{` executes the certificate path
validation algorithm for x.509 certificates defined in RFC 5280. If the
certificate is valid this command will return '0'. If validation fails, or if
an error occurs, this command will produce a non-zero return value.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
The path to a certificate to validate.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--host`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Check whether the certificate is for the specified host.`}</p>
    <p><strong parentName="p">{`--roots`}</strong>{`=`}<inlineCode parentName="p">{`roots`}</inlineCode>{`
Root certificate(s) that will be used to verify the
authenticity of the remote server.`}</p>
    <p><inlineCode parentName="p">{`roots`}</inlineCode>{` is a case-sensitive string and may be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`file`}</strong>{`: Relative or full path to a file. All certificates in the file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`list of files`}</strong>{`: Comma-separated list of relative or full file paths. Every PEM encoded certificate from each file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`directory`}</strong>{`: Relative or full path to a directory. Every PEM encoded certificate from each file in the directory will be used for path validation.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--servername`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
TLS Server Name Indication that should be sent to request a specific certificate from the server.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Verify a certificate using your operating system's default root certificate bundle:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate verify ./certificate.crt
`}</code></pre>
    <p>{`Verify a remote certificate using your operating system's default root certificate bundle:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate verify https://smallstep.com
`}</code></pre>
    <p>{`Verify a certificate using a custom root certificate for path validation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate verify ./certificate.crt --roots ./root-certificate.crt
`}</code></pre>
    <p>{`Verify a certificate using a custom list of root certificates for path validation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate verify ./certificate.crt \\
--roots "./root-certificate.crt,./root-certificate2.crt,/root-certificate3.crt"
`}</code></pre>
    <p>{`Verify a certificate using a custom directory of root certificates for path validation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate verify ./certificate.crt --roots ./root-certificates/
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      